"use client";
import { Controllerable, Mouseable, Styleable } from "@/@types";
import { ArrowIcon } from "@/assets/icons";
import { AnimatedUnderline } from "@/components/atoms";
import { HoverBorderGradient } from "@/components/ui/hover-border-button";
import { cn } from "@/lib/utils";
import React from "react";

type ButtonProps = {
  isStudioBtn?: boolean;
  disabled?: boolean;
  btnSize: "small" | "large";
  image?: string;
  icon?: JSX.Element;
  text: string;
  animationRequired: boolean;
  bgType: "solid" | "transparent";
  onClick?: () => void;
  href?: string;
  isUnderline?: boolean;
  isBlack?: boolean;
  iconSize?: number;
  showMobile?: boolean;
} & Styleable &
  Pick<Controllerable, "controller"> &
  Mouseable;

const CustomButton: React.FC<ButtonProps> = ({
  isStudioBtn,
  className,
  disabled,
  btnSize,
  image,
  icon,
  text,
  animationRequired,
  bgType,
  onClick,
  href,
  controller,
  onMouseEnter,
  onMouseLeave,
  isUnderline = false,
  isBlack = false,
  iconSize = 20,
  showMobile,
}) => {
  // Background type class
  const bgClass =
    bgType === "solid"
      ? "bg-[#632EE4] hover:bg-[#4619B5] border border-[#8558F2] [box-shadow:0px_1.378px_15.672px_0px_rgba(61,_30,_134,_0.69),_0px_0.344px_15.19px_0px_#201047_inset] text-white font-medium"
      : "bg-rt-black-b1 hover:bg-white/70 hover:text-black";

  const disabledClass = "bg-white/30 text-rt-grey-t5 cursor-not-allowed";

  const buttonSize =
    btnSize === "small" ? "px-[15px] py-[9px] text-sm" : "py-3 px-2 text-base";

  const StudioLinkDisable = isStudioBtn ? "hidden lg:flex" : "";

  const btnVisibility = showMobile ? "flex" : "hidden lg:flex";

  return (
    <>
      {disabled ? (
        <button
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          type="submit"
          onClick={onClick}
          className={cn(
            `flex flex-row rounded-[30px] justify-center items-center gap-2 w-auto hover:opacity-100 whitespace-nowrap ${
              disabled && disabledClass
            } ${buttonSize} ${btnVisibility} ${className}`
          )}
        >
          {image && <img src={image} alt="Button Image" className="w-8 h-8" />}
          {icon && <span>{icon}</span>}
          <span>{text}</span>
        </button>
      ) : bgType == "transparent" ? (
        <button
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          type="submit"
          onClick={onClick}
          className={cn(
            `flex flex-row px-[17px] py-[9px] rounded-[30px] justify-center items-center gap-2 w-auto hover:opacity-100 whitespace-nowrap ${bgClass} ${
              disabled && disabledClass
            } ${buttonSize} ${btnVisibility} ${className}`
          )}
        >
          {image && <img src={image} alt="Button Image" className="w-8 h-8" />}
          {icon && <span>{icon}</span>}
          <span className="leading-[160%]">{text}</span>
        </button>
      ) : (
        <button
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          type="submit"
          onClick={onClick}
          className="w-full flex"
        >
          <HoverBorderGradient
            as={"div"}
            className={cn(
              `flex flex-row rounded-[30px] justify-center items-center gap-2 w-full hover:opacity-100 whitespace-nowrap ${bgClass} 
       ${disabled && disabledClass} ${buttonSize} ${btnVisibility} ${className}`
            )}
          >
            {image && (
              <img src={image} alt="Button Image" className="w-8 h-8" />
            )}
            {icon && <span>{icon}</span>}
            <span>{text}</span>
          </HoverBorderGradient>
        </button>

        // <button
        //   onMouseEnter={onMouseEnter}
        //   onMouseLeave={onMouseLeave}
        //   type="submit"
        //   onClick={onClick}
        //   className={cn(
        //     `flex flex-row rounded-[30px] justify-center items-center gap-2 w-auto hover:opacity-100 whitespace-nowrap ${bgClass} ${
        //       disabled && disabledClass
        //     } ${buttonSize} ${btnVisibility} ${className}`
        //   )}
        // >
        //   {image && <img src={image} alt="Button Image" className="w-8 h-8" />}
        //   {icon && <span>{icon}</span>}
        //   <span className="leading-[160%]">{text}</span>
        // </button>
      )}
    </>
  );
};

export default CustomButton;
