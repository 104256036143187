"use client";
import { StickyNavbar } from "@/components/layout";
import { Earth, Globe } from "@/components/widgets";
import SectionAgents from "@/components/sections/SectionAgents";
import SectionHowitWorks from "@/components/sections/SectionHowItWorks";
import SectionUseCases from "@/components/sections/SectionUseCases";
import Hero from "@/components/sections/Hero";
import { navigationData } from "@/data";
import { v4 as uuidv4 } from "uuid";
import Picture from "@/components/ratl/utility/Picture";
import Image from "next/image";
import mascot from "../../public/ratl/ratl-mascot-1.png";
import CustomButton from "@/components/ratl/utility/CustonButton";
import { useFloat } from "@/lib";
import { useRouter } from "next/navigation";

/* eslint-disable @next/next/no-img-element */
export default function NotFound() {
  const { float, handleFloat } = useFloat([false, false]);
  const router = useRouter();
  return (
    <div className="md:mx-[156px] mx-5 flex flex-col gap-8">
      <div className="md:text-[209px] text-7xl text-rt-purple-p6 font-bold flex justify-center mt-32 md:mt-56 items-center">
        <h1>4</h1>
        <Image
          width={248}
          height={248}
          className="w-40 h-40 md:w-[248px] md:h-[248px]"
          src={mascot}
          alt="mascot"
        />
        <h1>4</h1>
      </div>
      <div className="flex justify-center items-center flex-col">
        <h1 className="gradient-text md:text-[40px] text-3xl leading-[120%]">
          Lost in the test lab!
        </h1>
        <p className="text-white md:text-2xl text-md leading-[120%]">
          Let's head back to a safer ground
        </p>
      </div>
      <div className="flex justify-center items-center w-fit mx-auto z-50">
        <CustomButton
          isStudioBtn={false}
          btnSize={"small"}
          text={"Take me home"}
          animationRequired={false}
          bgType={"solid"}
          controller={float[1]}
          onClick={() => router.push("/")}
          onMouseEnter={() => handleFloat(true, 1)}
          onMouseLeave={() => handleFloat(false, 1)}
        />
      </div>
    </div>
  );
}
